import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className='footer'>
            <p>
                Please don’t hesitate to reach out at <a id="a-footer" href="mailto:sales@doubledex.com">sales@doubledex.com</a> and we can assist you in the best way possible.
            </p>
        </div>
    )
}

export default Footer;