import React from 'react';
import'./Banner.css';
import SplashImg from './background.jpeg';

function Banner () {
    return (
        <div className='banner'>
            <img className='banner__splash' src={SplashImg} alt="Splash" width="100%"></img>
        </div>
    );
}

export default Banner;