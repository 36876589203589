import React from 'react';
import Banner from './Banner.js';

import './Store.css';
import eBayImg from './ebay.png';
import amazonImg from './amazon.png';
import walmartImg from './walmart.png';

function Store () {
    return (
        <div className='store'>
            <p id='firstParagraph'>{"If you're looking for parts or a refurbished laptop, please visit one of our online marketplaces below."}</p>
            <div>
                <a href="https://www.ebay.com/str/doubledexmacbooks"><img src={eBayImg} alt="eBay" width="50%"/></a>

                <a href="https://www.amazon.com/s?i=merchant-items&me=A1H8X0CYDLAX32"><img src={amazonImg} alt="Amazon" width="50%"/></a>
            </div>
            <p>
                <Banner />
            </p>
        </div>
    );
}

export default Store;