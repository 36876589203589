import React from 'react';
import './Header.css';
import headerLogo from './DD_Logo.svg';

function Header() {
    return (
        <div>
        <img className='header' src={headerLogo} alt="Header Logo here" />
        </div>
    );
}

export default Header;